import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "./global.css";

const AccordionContainer = styled.div`
  margin-bottom: 1rem;
  border-radius: 4px;
  border-top: 2px solid #f6f4ef;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
`;

const Icon = styled.span`
  font-weight: var(--fw300);
  font-size: 1.5rem;
  color: var(--text-color-white);
  margin-left: 1rem;
  background-color: ${({ isopen }) => (isopen === "true" ? "#F2651B" : "#242424")};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 1rem;
    width: 20px;
    height: 20px;
  }
`;

const AccordionContentWrapper = styled.div`
  overflow: hidden;
  transition: height 0.6s ease-in-out;
  height: ${({ isopen, height }) => (isopen === "true" ? `${height}px` : "0")};
  padding: ${({ isopen }) => (isopen === "true" ? "1rem" : "0 1rem")};
  background-color: white;
`;

const AccordionContentInner = styled.div`
  padding: 1.5rem;
  /* padding-bottom: 5rem; */
  margin-top: -35px;
`;

const AccordionContent = styled.div`
  font-family: Roboto;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Title = styled.h2`
  font-family: Roboto;
  font-size: var(--size32);
  font-weight: var(--fw300);
  line-height: 49px;
  text-align: left;
  color: #f0844e;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 24px;
  }
`;

const Accordion = ({ title, children, initialOpen = false, onToggle }) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    onToggle(title, !isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggleAccordion}>
        <TitleWrapper>
          <Title>{title}</Title>
          <Icon isopen={String(isOpen)}>{isOpen ? "-" : "+"}</Icon>
        </TitleWrapper>
      </AccordionHeader>
      <AccordionContentWrapper isopen={String(isOpen)} height={height}>
        <AccordionContent ref={contentRef}>
          <AccordionContentInner>{children}</AccordionContentInner>
        </AccordionContent>
      </AccordionContentWrapper>
    </AccordionContainer>
  );
};

export default Accordion;
