import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { setConfiguration } from "react-grid-system";

import { RobotoFontWrapper } from "./RobotoFont";
import { SmallPillsTag } from "./SmallPillsTag";
import { LearnMoreBtn } from "./LearnMoreBtn";
import RightGrayArrow from "./images/RightGrayArrow.svg";
import MarketAnalysisImg from "./images/MarketAnalysisImg.svg";
import NewProductDevelopment from "./images/NewProductDevelopment.svg";
import ApplicationUseCaseAnalysis from "./images/ApplicationUseCaseAnalysis.svg";
import TechnologyAnalysis from "./images/TechnologyAnalysis.svg";
import NewBusinessCreation from "./images/NewBusinessCreation.svg";
import "./global.css";
import { userTrack } from "../Common";
import Image from "next/image";
import getConfig from "next/config";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810],
});

const CenterBox = styled.div`
  max-width: 1209px;
  margin: 0 auto;
`;

const MainWrapper = styled.section`
  /* height: 998px; */
  height: 950px;
  background: #fff;
overflow-x:hidden;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 1312px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  margin: 0 auto;
  color: var(--text-color-black);
  margin-top: 100px;
  margin-bottom: 70px;
  width: 773px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    width: 240px;
    margin-top: 60px;
  }
`;

const FullWidthImg = styled.div`
  position: relative;
  background-image: url(${(props) => props.imgSrc});
  background-size: cover;
  background-position: center;
  width: 600px;
  height: 100%;
  margin-left: 67px;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 200px;
    width: 100%;
    margin-left: inherit;
  }
`;

const VirticalTabWrapper = styled.div`
  display: flex;
  max-width: 1350px;
  margin: 0 auto;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 7px;
  border-bottom: 1px solid #eaeaea;
  background: ${(props) =>
    props.isactive == "true" ? props.activebgcolor : props.backgroundcolor};
  position: relative;
  min-height: 88px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background: ${(props) => props.hovercolor};
  }

  &:hover .learn-more-btn {
    display: flex;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: initial;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #515151;
  margin-bottom: 0px;
  width: 641px;
  margin-top: 14px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 18px;
    line-height: 29px;
    width: 265px;
  }
`;

const ButtonWrapper = styled.div`
  width: 548px;
  position: absolute;
  left: 459px;
  top: -5px;

  @media screen and (max-width: 767px) {
    width: 330px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 149px;
    position: absolute;
    left: 221px;
    top: -3px;
  }

  &.learn-more-btn {
    /* display: flex; */
  }
`;

const ArrowWrapper = styled.div`
  position: relative;
`;

const RightArrowImg = styled.div`
  position: absolute;
  top: 30px;
  left: -50px;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const PillsBox = styled.div`
  position: relative;
`;


const tabData = [
  {
    bgColor: "#e9f2ff",
    activeBgColor: "#d0e7ff",
    hovercolor: "#C8DFFF",
    label: "Market Analysis",
    width: "140px",
    height: "35px",
    bgColorTag: "#DDEEFF",
    text: "Visualize Market & technology relationships using teardowns.",
    imgSrc: MarketAnalysisImg,
  },
  {
    bgColor: "#eefff0",
    activeBgColor: "#d0ffd1",
    hovercolor: "#D6FFDC",
    label: "New Business Creation",
    width: "180px",
    height: "35px",
    bgColorTag: "#BEFEC7",
    text: "Quickly find new options for your product or material.",
    imgSrc: NewBusinessCreation,
  },
  {
    bgColor: "#f8f8f8",
    activeBgColor: "#e0e0e0",
    hovercolor: "#e0e0e0",
    label: "Application Use Case Analysis",
    width: "220px",
    height: "35px",
    bgColorTag: "#EDF0F2",
    text: "Develop an exhaustive portfolio of emerging early stage use cases & prospective customers.",
    imgSrc: ApplicationUseCaseAnalysis,
  },
  {
    bgColor: "#ffeeef",
    activeBgColor: "#ffdadb",
    hovercolor: "#FEC5C8",
    label: "Technology Analysis",
    width: "180px",
    height: "35px",
    bgColorTag: "#FFDEE0",
    text: "Find novel tech. Extract stealth, unknown & conceptual technologies globally.",
    imgSrc: TechnologyAnalysis,
  },
  {
    bgColor: "#f6eaff",
    activeBgColor: "#e4d1ff",
    hovercolor: "#E1B7FF",
    label: "New Product Development",
    width: "220px",
    height: "35px",
    bgColorTag: "#F0DBFF",
    text: "Analyze Customer segments & hard-to-find collection of market pain points.",
    imgSrc: NewProductDevelopment,
  },
];

const USPSection = React.memo(() => {
  const [activeTab, setActiveTab] = useState(0);
  const [mouseMove, setmouseMove] = useState(7);

  const { publicRuntimeConfig } = getConfig();


  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const ClickHandler = async (Title) => {
    if (Title == "Market Analysis") {
      await userTrack(
        "",
        "Onclick of Learn More Market Analysis-Research Goal Section",
        "HomePage"
      );
      window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/Market-Analysis" : "/ResearchGoal/Market-Analysis.html";
    }
    if (Title == "Technology Analysis") {
      await userTrack(
        "",
        "Onclick of Learn More Technology Analysis-Research Goal Section",
        "HomePage"
      );
      window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/Technology-Analysis" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/Technology-Analysis.html";
    }
    if (Title == "New Business Creation") {
      await userTrack(
        "",
        "Onclick of Learn More New Business Creation-Research Goal Section",
        "HomePage"
      );
      window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/New-Business-Creation" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/New-Business-Creation.html";
    }
    if (Title == "New Product Development") {
      await userTrack(
        "",
        "Onclick of Learn More New Product Development-Research Goal Section",
        "HomePage"
      );
      window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/New-Product-Development" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/New-Product-Development.html";
    }
    if (Title == "Application Use Case Analysis") {
      await userTrack(
        "",
        "Onclick of Learn More Application Use Case Analysis-Research Goal Section",
        "HomePage"
      );
      window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/Use-Case-Analysis" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/Use-Case-Analysis.html";
    }
  };

  return (
    <RobotoFontWrapper>
      <MainWrapper>
        <CenterBox>
          <Container>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <Title>Get Tailored Results for any Research Goal</Title>
              </Col>
            </Row>
          </Container>
        </CenterBox>

        <VirticalTabWrapper>
          <FullWidthImg>
            <Image priority={true}
              className="usps-section-mobile"
              height={600}
              src={
                tabData[mouseMove]
                  ? tabData[mouseMove]?.imgSrc
                  : tabData[activeTab].imgSrc
              }
              alt={tabData[mouseMove]
                ? tabData[mouseMove]?.text
                : tabData[activeTab].text}
              style={{ backgroundColor: "#ffffff" }}
            />
          </FullWidthImg>
          <div>
            {tabData.map((tab, index) => (
              <Wrapper
                key={index}
                // backgroundcolor={activeTab === index ? tab.bgColor : ""}
                activebgcolor={mouseMove != 7 ? "" : activeTab === index ? tab.activeBgColor : ""}
                hovercolor={tab.hovercolor}
                isactive={String(activeTab === index)}
                onClick={() => {
                  setActiveTab(index);
                }}
                onMouseEnter={() => setmouseMove(index)}
                onMouseLeave={() => setmouseMove(7)}
              >
                {(mouseMove == 7 ? (activeTab === index) : (mouseMove == index)) && (
                  <ArrowWrapper>
                    <RightArrowImg><Image priority={true} src={RightGrayArrow} alt="RightGrayArrow" /></RightArrowImg>
                  </ArrowWrapper>
                )}
                <PillsBox>
                  <SmallPillsTag
                    width={tab.width}
                    height={tab.height}
                    bgcolor={tab.bgColorTag}
                  >
                    {tab.label}
                  </SmallPillsTag>
                  <ButtonWrapper
                    onClick={() => ClickHandler(tab.label)}
                    className="learn-more-btn"
                  >
                    <LearnMoreBtn height="37px">Learn More</LearnMoreBtn>
                  </ButtonWrapper>
                </PillsBox>
                <Text>{tab.text}</Text>
              </Wrapper>
            ))}
          </div>
        </VirticalTabWrapper>
      </MainWrapper>
    </RobotoFontWrapper>
  );
});

export default USPSection;
