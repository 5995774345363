//Library Imports
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { setConfiguration } from "react-grid-system";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import Accordion from "./Accordion";
import { BlackBtn } from "./BlackBtn";
import { userTrack } from "../Common";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  min-height: 1000px;
  background: #fff;
  padding-bottom: 40px;
  overflow-x:hidden;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  color: var(--text-color-black);
  width: 888px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 70px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    width: 300px;
    margin-top: 34px;
  }
`;

const BoldText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: var(--lh18);
  text-align: left;
  color: var(--text-color-dark-liver);
  @media (min-width: 320px) and (max-width: 480px) {
    line-height: var(--lh25);
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh22);
  text-align: left;
  color: var(--text-color-dark-liver);

  @media (min-width: 320px) and (max-width: 480px) {
    line-height: var(--lh25);
  }
`;

const Space = styled.div`
  margin-bottom: 20px;
`;
const FAQSection = React.memo(() => {

  const { publicRuntimeConfig } = getConfig();


  const HandleCorporate = async () => {
    await userTrack(
      "",
      "Onclick of Discover More for Corporate and Strategy",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/Corporate-Strategy" : publicRuntimeConfig.assetPrefix + "/Team/Corporate-Strategy.html";
  };

  const HandleRandD = async () => {
    await userTrack(
      "",
      "Onclick of Discover More for R&D and Technology Scouting",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/R&D-and-Tech-Scouting" : publicRuntimeConfig.assetPrefix + "/Team/R&D-and-Tech-Scouting.html";
  };

  const HandleSales = async () => {
    await userTrack(
      "",
      "Onclick of Discover More for Sales and Marketing",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/Sales-and-Marketing" : publicRuntimeConfig.assetPrefix + "/Team/Sales-and-Marketing.html";
  };

  const HandleCVC = async () => {
    await userTrack("", "Onclick of Discover More for CVC and M&A", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/CVC-and-M&A" : publicRuntimeConfig.assetPrefix + "/Team/CVC-and-M&A.html";
  };

  const HandleNewBusiness = async () => {
    await userTrack(
      "",
      "Onclick of Discover More for New Business Development",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/New-Business-Development" : publicRuntimeConfig.assetPrefix + "/Team/New-Business-Development.html";
  };

  const handleToggle = (title, isOpen) => {
    userTrack("", `Accordion at ${title} is now ${isOpen ? "open" : "closed"}`);
  };

  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <Title>
                  A Complete Business Research
                  <br /> Platform for any team
                </Title>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <div>
                  <Accordion
                    title="Corporate and Strategy"
                    onToggle={handleToggle}
                    initialOpen={true}
                  >
                    <BoldText>
                      Setup listening posts to smartly monitor your markets.
                    </BoldText>
                    <Text>
                      Interpret market signals across the ecosystem with
                      continuous monitoring & hypothesis generation solutions.
                    </Text>
                    <Space></Space>
                    <BlackBtn onClick={HandleCorporate}>Discover More</BlackBtn>
                  </Accordion>
                  <Accordion
                    title="R&D and Technology Scouting"
                    onToggle={handleToggle}
                  >
                    <BoldText>
                      Technology Scouting need to be fused with market and
                      trends data.
                    </BoldText>
                    <Text>
                      Identify technologies that can disrupt your firm and your
                      target industry.
                    </Text>
                    <Space></Space>
                    <BlackBtn onClick={HandleRandD}>Discover More</BlackBtn>
                  </Accordion>
                  <Accordion title="Sales & Marketing" onToggle={handleToggle}>
                    <BoldText>
                      Create winning proposals with timely insights.
                    </BoldText>
                    <Text>
                      Use Ideapoke to monitor the competition, embed Ideapoke'
                      insights into your proposals.
                    </Text>
                    <Space></Space>
                    <BlackBtn onClick={HandleSales}>Discover More</BlackBtn>
                  </Accordion>
                  <Accordion title="CVC and M&A" onToggle={handleToggle}>
                    <BoldText>
                      Come up with a hypothesis using deep technological
                      analysis of startups.
                    </BoldText>
                    <Text>
                      Visualize startups on a map, go broader and deeper into a
                      companies details to get to the top 5 candidates
                      confidently.
                    </Text>
                    <Space></Space>
                    <BlackBtn onClick={HandleCVC}>Discover More</BlackBtn>
                  </Accordion>
                  <Accordion
                    title="New Business Development"
                    onToggle={handleToggle}
                  >
                    <BoldText>Zoom out, then zoom in.</BoldText>
                    <Text>
                      Be it 10 year plans or short term plans, Ideapoke provides
                      NBD teams with a wide cross-section of data and landscapes
                      across emerging technologies and upcoming markets.
                    </Text>
                    <Space></Space>
                    <BlackBtn onClick={HandleNewBusiness}>
                      Discover More
                    </BlackBtn>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default FAQSection;
