//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
export const LearnMoreBtn = styled.button`
  font-family: Roboto;
  width: fit-content;
  height: ${({ height }) => height || "56px"};
  border-radius: 100px;
  background-color: transparent;
  color: var(--text-color-chinese-black);
  font-size: var(--size14);
  font-weight: var(--fw400);
  line-height: var(--lh18);
  text-align: center;
  outline: none;
  cursor: pointer;
  border: none;

  &::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjI0NTEgMS44NzAxMlYxMS4zOTc1SDE2LjE5MDRWMy42NjMwOUw1LjI1Njg0IDE0LjU5NjdMNC41MTg1NSAxMy44MjMyTDE1LjQxNyAyLjkyNDhINy42ODI2MlYxLjg3MDEySDE3LjI0NTFaIiBmaWxsPSIjMTcxNzE3Ii8+Cjwvc3ZnPgo=");
    position: relative;
    top: 5px;
    left: 5px;
  }
`;

export const ExtendedLearnMoreBtn = styled(LearnMoreBtn)`
  font-size: 14px;

  &::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjcwNTYgMC44NzAxMTdWMTAuMzk3NUgxMi42NTA5VjIuNjYzMDlMMS43MTcyOSAxMy41OTY3TDAuOTc5MDA0IDEyLjgyMzJMMTEuODc3NCAxLjkyNDhINC4xNDMwN1YwLjg3MDExN0gxMy43MDU2WiIgZmlsbD0iIzE3MTcxNyIvPgo8L3N2Zz4K");
    position: relative;
    top: 3px;
    left: 6px;
  }
`;
