//Library Imports
import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { OrangeOutlineBtn } from "./OrangeOutlineBtn";
import Newsletter from "./NewsLetter";
import { userTrack } from "../Common";

// CSS-in-JS
const MainWrapper = styled.section`
  height: 285px;
  display: flex;
  /* max-width: 1209px; */
  justify-content: center;
  align-items: center;
  background: linear-gradient(177.26deg, #114a82 10.7%, #8399e9 103.87%);

  @media (min-width: 320px) and (max-width: 480px) {
    height: 400px;
  }
`;

const NewsletterWrapper = styled.section`
  display: flex;
  gap: 30px;
  align-items: center;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size45);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  color: var(--text-color-white);

  span {
    font-weight: var(--fw300);
  }

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: var(--size35);
    font-weight: var(--fw200);
    line-height: 47px;
    width: 270px;
    margin: 0 auto;
  }
`;

const NewsletterSection = React.memo((props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    userTrack("", "Onclick  of Subscribe", "Homepage");
  };
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <NewsletterWrapper>
                  <Title>
                    Subscribe to our Newsletter: <span>Ideapoke Pulse</span>
                  </Title>
                  <OrangeOutlineBtn
                    onClick={openModal}
                    width="236px"
                    height="56px"
                  >
                    Subscribe
                  </OrangeOutlineBtn>
                </NewsletterWrapper>
                {isModalOpen && <Newsletter setMessageForSucessBanner={props.setMessageForSucessBanner} setSuccessBanner={props.setSuccessBanner} mainHeading="Newsletter" onClose={closeModal} />}
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default NewsletterSection;
