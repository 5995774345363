//Library Imports
import React from "react";
import styled, { keyframes } from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { setConfiguration } from "react-grid-system";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { BlackBtn } from "./BlackBtn";

// Import Images
import ArrowRight from "./images/ArrowRight.png";
import IconPlaceholder from "./IconPlaceholder";
import BlockIcon from "./images/BlockIcon.svg";
import CircleAIIcon from "./images/CircleAIIcon.svg";
import PeopleIcon from "./images/PeopleIcon.svg";
import { userTrack } from "../Common";
import Image from "next/image";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  /* width: 1684px; */
  max-width: 1684px;
  margin: 0 auto;
  height: 750px;
  /* border: 1px solid red; */
  background: #fff;
  overflow-x:hidden;
/* margin-right:10px; */

  @media (min-width: 320px) and (max-width: 480px) {
    height: 1442px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 100px;
  margin-bottom: 25px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    margin-top: 60px;
    text-align: center;
  }
`;

const Tag = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh26);
  text-align: left;
  color: var(--text-color-dark-liver);
  margin-bottom: 25px;

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw500);
  line-height: 26px;
  text-align: left;
  color: var(--text-color-dark-liver);
  margin-top: 30px;
  margin-bottom: 30px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: var(--size18);
    text-align: center;
  }
`;

const Box = styled.div`
  margin-left: 60px;
  margin-top: 75px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 21px;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -54px;
  }
  50% {
    right: -64px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -54px;
  top: 184px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const IconTitle = styled.p`
  font-family: Roboto;
  font-size: var(--size24);
  font-weight: var(--fw400);
  line-height: 28px;
  text-align: left;
  color: var(--text-color-chinese-black);
  margin-top: 35px;
  margin-bottom: 20px;
  width: 400px;
  text-wrap: wrap;
  @media (min-width: 576px) and (max-width: 1400px) {
    width:300px;
    font-size: var(--size20)
  }

`;

const IconText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: 26px;
  text-align: left;
  color: var(--text-color-dark-liver);
  width: 300px;
  @media (min-width: 576px) and (max-width: 1400px) {
    width:210px;
    font-size: var(--size15)
  }

`;

const NegativeMargin = styled.div`
  margin-top: -40px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 0px;
  }
`;

const BulletSection = React.memo(() => {


  const { publicRuntimeConfig } = getConfig();

  const LearnmorecapabilitiesHandler = async () => {
    await userTrack(
      "",
      "Onclick of Learn more about our capabilities",
      "Platform"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/AI-Business-Research-Platform" : publicRuntimeConfig.assetPrefix + "/AI-Business-Research-Platform.html";
  };
  //
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={6} lg={6} sm={6} xs={12}>
                <ArrowWrapper>
                  <Img><Image alt="ArrowRight" src={ArrowRight} /></Img>
                </ArrowWrapper>

                <Title>
                  Next Gen Research. <br /> With Generative AI.
                </Title>

                <Tag>Forget Generic AI tools!</Tag>
                <Text>
                  Our AI models, trained on a decade of real-world data can
                  provide the kind of insights researchers & strategists want.
                </Text>
                <div>
                  <BlackBtn
                    onClick={LearnmorecapabilitiesHandler}
                    width="336px"
                    height="56px"
                  >
                    Learn more about our capabilities
                  </BlackBtn>
                </div>
              </Col>
              <Col xl={6} lg={6} sm={6} xs={12}>
                <Box>
                  <Row>
                    <Col xl={7} lg={7} sm={7} xs={12}>
                      <div>
                        <IconPlaceholder src={BlockIcon} bgColor="#f6c143" />
                      </div>
                      <IconTitle>Any Research. One Platform</IconTitle>
                      <IconText>
                        Go from Broad search to Deep Dives all on one platform.
                      </IconText>
                    </Col>
                    <Col xl={5} lg={5} sm={5} xs={12}>
                      <div>
                        <IconPlaceholder src={CircleAIIcon} bgColor="#baea86" />
                      </div>
                      <IconTitle>AI meant for Research</IconTitle>
                      <IconText>
                        With AI algorithms trained on over a million
                        customer-analyst interactions and 1000+ research
                        analyses, our outputs meet the expectations of business
                        researchers.
                      </IconText>
                    </Col>

                    <Col xl={6} lg={6} sm={6} xs={12}>
                      <NegativeMargin>
                        <IconPlaceholder src={PeopleIcon} bgColor="#a2befb" />
                      </NegativeMargin>
                      <IconTitle>Collaborative Research</IconTitle>
                      <IconText>
                        Our Research Experts with a combined experience of over
                        100 years support you through your research to help you
                        shape winning strategies.
                      </IconText>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default BulletSection;
