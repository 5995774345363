/* eslint-disable react/no-unescaped-entities */
//
//Library Imports
import React, { useState } from "react";
import styled from "styled-components";

// CSS Imports
import "./global.css";

// Import Images
import ActiveArrowRight from "./images/ActiveArrowRight.svg";
import ActiveArrowLeft from "./images/ActiveArrowLeft.svg";
import NOActiveArrowRight from "./images/NOActiveArrowRight.svg";
import NOActiveArrowLeft from "./images/NOActiveArrowLeft.svg";
import QuoteIcon from "./images/QuoteIcon.svg";
import ReviewLogo1 from "./images/ReviewLogo1.svg";
import ReviewLogo2 from "./images/ReviewLogo2.svg";
import ReviewLogo3 from "./images/ReviewLogo3.svg";
import Image from "next/image";

// Sample data for testimonials
const testimonials = [
  {
    review:
      "Across many years, the Ideapoke platform has provided us unbiased AI generated trends and insights across various innovation topics. This allows our internal teams to bring a new level of operational efficiency that can help us properly understand market opportunities and reduce our time to generate strategy roadmaps.",
    customerName: "Takashi Zack Okazaki",
    position: "Head of U.S. R&D Nippon Life X Silicon Valley ",
    //companyName: "",
    //logo: "https://via.placeholder.com/50",
    logo: ReviewLogo1,
  },

  {
    review:
      "Through the use of this platform, we were able to obtain new technology information. By using Ideapoke, I decided our roadmap. This decision has been of a very high value all over the company.",
    customerName: "Takuji Maekawa",
    position: "R&D Department, ",
    //companyName: "",
    //logo: "https://via.placeholder.com/50",
    logo: ReviewLogo2,
  },

  {
    review:
      "We highly recommend Ideapoke to companies working on formulating future strategies that need top quality market and technology intelligence.",
    customerName: "Dongdong Wang",
    position: "General Manager Panasonic ",
    //companyName: "",
    logo: ReviewLogo3,
  },
];

// CSS-in-JS
const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 700px;
  height: 400px;
  border-radius: 20px;
  border: 1px solid #ffc7b0;
  margin-left: 20px;
  margin-top: 40px;
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 0;
    width: 315px;
    height: 620px;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
`;

const ReviewText = styled.p`
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: var(--size18);
  font-weight: var(--fw300);
  line-height: var(--lh30);
  text-align: left;
  color: var(--text-color-dark-liver);
  width: 640px;
  position: relative;
  left: 43px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 240px;
  }
`;

const CustomerInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  left: 43px;
  top: 9px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 270px;
  }
`;

const Logo = styled.div`
  width: 186px;
  height: 127px;
  /* background-image: url(${(props) => props.logo}); */
  /* background-size: calc(100% - 90px); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* border-radius: 6px; */
  margin-right: 15px;
  background-color: #fff5f0;
  border: 1px solid #e3dfdf;
`;

const CustomerDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomerName = styled.span`
  font-family: Roboto;
  font-size: var(--size18);
  font-weight: var(--fw600);
  line-height: var(--lh20);
  text-align: left;
  color: var(--text-color-halloween-orange);
  margin-bottom: 8px;
`;

const Position = styled.span`
  font-family: Roboto;
  font-size: var(--size18);
  font-weight: var(--fw300);
  line-height: var(--lh26);
  text-align: left;
  color: #515151;
  margin-bottom: 8px;
`;

const CompanyName = styled.span`
  font-family: Roboto;
  font-size: var(--size18);
  font-weight: var(--fw400);
  line-height: var(--lh26);
  text-align: left;
  margin-bottom: 8px;
`;

const RightArrow = styled.div`
  background-image: url(${ActiveArrowRight.src});
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;

  /* &:hover {
    background-image: url(${NOActiveArrowRight});
    height: 30px;
    width: 30px;
    transform: scale(1.1);
  } */
`;

const LeftArrow = styled.div`
  background-image: url(${ActiveArrowLeft.src});
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;

  /* &:hover {
    background-image: url(${NOActiveArrowLeft});
    height: 30px;
    width: 30px;
    transform: scale(1.1);
  } */
`;

const Img = styled.div`
  position: absolute;
  left: 24px;
`;

// Testimonial Component
const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { review, customerName, position, companyName, logo } =
    testimonials[currentIndex];

  return (
    <TestimonialContainer>
      <div>
        <Img><Image alt="QuoteIcon" src={QuoteIcon} /></Img>
      </div>
      <Navigation>
        <ArrowButton aria-label="Prev" onClick={handlePrevious}>
          <LeftArrow />
        </ArrowButton>
        <ArrowButton aria-label="Next" onClick={handleNext}>
          <RightArrow />
        </ArrowButton>
      </Navigation>

      <ReviewText>"{review}"</ReviewText>
      <CustomerInfo>
        <Logo><Image className="customertestimonial-mobile" alt={customerName} src={logo} /></Logo>
        <CustomerDetails>
          <CustomerName>{customerName}</CustomerName>
          <Position>{position}</Position>
          <CompanyName>{companyName}</CompanyName>
        </CustomerDetails>
      </CustomerInfo>
    </TestimonialContainer>
  );
};

export default Testimonial;
