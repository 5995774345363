//
//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
export const SmallPillsTag = styled.button`
  font-family: Roboto;
  width: ${({ width }) => width || "160px"};
  height: ${({ height }) => height || "40px"};
  border-radius: 12px;
  background-color: ${({ bgcolor }) => bgcolor || "#e0edff"};
  color: #14181f;
  font-size: var(--size14);
  font-weight: var(--fw500);
  line-height: var(--lh25);
  text-align: center;
  outline: none;
  border: none;
`;
