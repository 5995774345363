import React, { useEffect } from 'react'
import styled from 'styled-components'
import { RobotoFontWrapper } from '../IPWebsiteRedesign/RobotoFont'

const NotifyWrap = styled.div`
  background-color: ${(props) => props.BackColor}; /* #D4FFDE */
  width: ${(props) => props.width ? props.width : "100%"};
  
  position: fixed;
  top: 0;
  box-shadow: 0px 30px 60px 0px rgba(69, 69, 69, 0.25);
  border-radius: 0 0 10px 10px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  @media(max-width:600px){
    top:12%;
  }
`

const NotifyTxt = styled.p`
    margin: 0;
    text-align: center;
    font-family: Roboto;
    font-size:${(props) => props.fontSize ? props.fontSize : "20px"}; 
    font-style: normal;
    font-weight: 500;
    line-height: 32px; 
    color: ${(props) => props.color}; /* #1F936E */
    padding: 14px 16px;

    @media screen and (max-width: 767px){
      line-height: ${(props) => props.MoblineHeight}; 
      font-size: ${(props) => props.MobfontSize};
    }
`

export default function NotifyBanner(props) {

  useEffect(() => {
    setTimeout(() => {

      props.setSuccessBanner(false);

    }, 7000);
  }, [props.successBanner])


  return (
    <div>
      <RobotoFontWrapper>
        <NotifyWrap BackColor={props.BackColor} width={props.width}>

          <NotifyTxt fontSize={props.fontsize} MoblineHeight={props.MoblineHeight} MobfontSize={props.MobfontSize} color={props.color}>{props.Notify}</NotifyTxt>
        </NotifyWrap>
      </RobotoFontWrapper>
    </div>
  )
}
