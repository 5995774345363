//Library Imports
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { setConfiguration } from "react-grid-system";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import CustomerTestimonial from "./CustomerTestimonial";

// Import Images
import FeatureIcon from "./images/FeatureIcon.svg";
import { BlackBtn } from "./BlackBtn";
import { userTrack } from "../Common";
import Image from "next/image";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  height: 1000px;
  background: var(--bg-color-white);

  @media (min-width: 320px) and (max-width: 480px) {
    height: 1677px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  color: var(--text-color-black);
  width: 848px;
  margin: 0 auto;
  margin-bottom: 70px;
  margin-top: 70px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 47px;
    width: 280px;
    margin-bottom: 10px;
  }
`;

const BulletTitle = styled.h2`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 300;
  line-height: 49px;
  text-align: left;
  color: #f0844e;
`;

const ListWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Img = styled.div`
  position: relative;
  top: 7px;
`;

const ListText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: var(--lh30);
  text-align: left;
  color: var(--text-color-dark-liver);
  margin-top: 0px;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  margin-top: 60px;
  margin-left: 30px;
`;

const ReviewSection = React.memo(() => {

  const { publicRuntimeConfig } = getConfig();

  const HandleInteractiveCustomerStories = async () => {
    await userTrack("", "Onclick  of See More Customer Stories ", "Homepage");
    window.location.href = process.env.NODE_ENV == "development" ? "/interactivestories" : publicRuntimeConfig.assetPrefix + "/interactivestories.html";
  };
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <Title>
                  Helping Global Market leaders make data-driven decisions
                  everyday
                </Title>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col xl={4} lg={4} sm={4} xs={12}>
                <BulletTitle>Our Customers:</BulletTitle>
                <div>
                  <ListWrap>
                    <Img><Image priority={true} alt="FeatureIcon" src={FeatureIcon} /></Img>
                    <ListText>
                      Saved 10-15 hours of research time per week. Unlocking
                      more time for other tasks.
                    </ListText>
                  </ListWrap>

                  <ListWrap>
                    <Img><Image priority={true} alt="FeatureIcon" src={FeatureIcon} /></Img>
                    <ListText>
                      Overcame research challenges such as data fragmentation,
                      and lack of specialized expertise.
                    </ListText>
                  </ListWrap>

                  <ListWrap>
                    <Img><Image priority={true} alt="FeatureIcon" src={FeatureIcon} /></Img>
                    <ListText>
                      Took quick data-driven decisions to move forward in their
                      research process
                    </ListText>
                  </ListWrap>
                </div>
              </Col>

              <Col xl={8} lg={8} sm={8} xs={12}>
                <CustomerTestimonial />
                <ButtonWrapper>
                  <BlackBtn
                    onClick={HandleInteractiveCustomerStories}
                    width="320px"
                    heigth="56px"
                  >
                    See More Customer Stories
                  </BlackBtn>
                </ButtonWrapper>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default ReviewSection;
