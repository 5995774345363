import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import { setConfiguration } from "react-grid-system";
import { Fade } from "react-awesome-reveal";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { PillsTag } from "./PillsTag";
import { MagicBlackBtn } from "./MagicBlackBtn";
import { OrangePillsBtn } from "./OrangePillsBtn";
import TabSwitcher from "./TabSwitcher";

// Import Images
import ArrowLeft from "./images/ArrowLeft.svg";
import { userTrack } from "../Common";
import Newsletter from "./NewsLetter";

// Import Videos
import Video4 from "./video/Video4.mp4";
import Video5 from "./video/Video5.mp4";
import Video11 from "./video/Video11.mp4";
import VideoGif4 from "./video/VideoGif4.gif";
import VideoGif5 from "./video/VideoGif5.gif";
import VideoGif6 from "./video/VideoGif6.gif";
import Image from "next/image";
import getConfig from "next/config";

// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  height: 930px;
  background: #fff;

  @media (min-width: 320px) and (max-width: 480px) {
    height: auto;
  }
`;

const Video = styled.video`
  position: relative;
  top: 45px;
  /* /* margin-left: 98px; */
  width: 620px; 
  margin-left: 100px;
  /* width: 500px; */
  border-radius: 10px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);

  @media (min-width: 576px) and (max-width: 1400px) {
    width: 480px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const Box = styled.div`
  margin-top: 45px;
  margin-bottom: 100px;
`;

const DoubleBtnWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 788px;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    width: 275px;
    margin: 0 auto;
    align-items: center;
  }
`;

const MagicBtnWrapper = styled.div`
  position: relative;
`;

const ExtendedOrangePillsBtn = styled(OrangePillsBtn)`
  position: absolute;
  right: 5px;
  top: -21px;
  width: 86px;
  height: 30px;
  background-color: #dcfce7;
  border: 1px solid #00a81c;
  color: #00a81c;
  z-index: 1;
`;

const ArrowWrapper = styled.div`
  position: relative;

  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -104px;
  }
  50% {
    right: -114px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -104px;
  top: -20px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const StrongTextWrapper = styled.div`
  display: flex;
  margin-top: 70px;
  @media (min-width: 320px) and (max-width: 480px) {
    justify-content: center;
    position: relative;
    left: -17px;
  }
`;

const StrongText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw700);
  line-height: var(--lh26);
  text-align: left;
  color: var(--text-color-dark-liver);
`;
const HeroImgWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 auto;
    width: 300px;
  }
`;

const HeroImg = styled.div`
  position: relative;
  top: -43px;
  margin-left: 150px;
  width: 650px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  border-radius: 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
`;
const OrangeSection = React.memo((props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const [activeTab, setActiveTab] = useState(0);

  const openModal = () => setModalOpen(true);
  const HandleSummarization = async () => {
    await userTrack("", "Onclick of AI Summarization", "Platform");
    window.location.href = "/ai-tools/summarization.html";
  };
  const HandleSearch = async () => {
    await userTrack("", "Onclick of AI Company Search", "Platform");
    window.location.href = "/ai-tools/companysearch.html";
  };
  const HandleVideoTranscription = async () => {
    openModal();
    await userTrack("", "Onclick of AI Video Transcription", "Platform");
  };

  const videos = [Video4, Video5, Video11];
  const gifs = [VideoGif4, VideoGif5, VideoGif6];

  

  const { publicRuntimeConfig } = getConfig();
  const videoBasePath = publicRuntimeConfig.assetPrefix || '';


  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col xl={6} lg={6} sm={6} xs={12}>
                <Box>
                  <PillsTag
                    as="div"
                    width="250px"
                    height="49px"
                    backgroundcolor="#FBE2CA"
                    color="#BA7737"
                  >
                    <b>AI</b>
                    <span>Research Tools</span>
                  </PillsTag>
                  <TabSwitcher
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  <StrongTextWrapper>
                    <StrongText>Try Now</StrongText>
                    <ArrowWrapper>
                      <Img><Image alt="ArrowLeft" src={ArrowLeft} /></Img>
                    </ArrowWrapper>
                  </StrongTextWrapper>

                  <DoubleBtnWrapper>
                    <MagicBlackBtn
                      onClick={HandleSummarization}
                      width="239px"
                      height="56px"
                    >
                      <strong>AI</strong> Summarization
                    </MagicBlackBtn>
                    <MagicBlackBtn
                      onClick={HandleSearch}
                      width="239px"
                      height="56px"
                    >
                      <strong>AI</strong> Company Search
                    </MagicBlackBtn>
                    <MagicBtnWrapper>
                      <MagicBlackBtn
                        onClick={HandleVideoTranscription}
                        width="278px"
                        height="56px"
                        style={{ zIndex: 1, position: "relative" }}
                      >
                        <strong>AI</strong> Video Transcription
                      </MagicBlackBtn>
                      <ExtendedOrangePillsBtn>
                        Early Access
                      </ExtendedOrangePillsBtn>
                    </MagicBtnWrapper>
                  </DoubleBtnWrapper>
                </Box>
              </Col>
              {isModalOpen && (
                <Newsletter
                  setMessageForSucessBanner={props.setMessageForSucessBanner}
                  setSuccessBanner={props.setSuccessBanner}
                  mainHeading="AI Video Transcription - Early Access"
                  forEarlyAccess={true}
                  onClose={closeModal}
                />
              )}

              <Col xl={6} lg={6} sm={6} xs={12}>
                <Fade duration={1500} fraction={0.5} triggerOnce={true}>
                  <HeroImgWrapper>
                    <Hidden xs>
                      <Video
                        width="100%"
                        height="auto"
                        autoPlay
                        muted
                        loop
                           playsInline
                          preload="metadata"
                        key={activeTab} // Key to force re-render on tab change
                      >
                        <source alt={activeTab == 0 ? "AI Research Tools:Summarize. Query. Get Answers" : activeTab == 1 ? "AI Research Tools:Search Topics. Scout Companies. Get rich profiles" : activeTab == 2 ? "AI Research Tools:Share a Link. Summarize. Jump to Insights" : ""} src={videoBasePath + videos[activeTab]} type="video/mp4" />
                      </Video>
                    </Hidden>
                    <Visible xs>

                      <HeroImg ><Image priority={false} src={gifs[activeTab]} style={{ width: "100%", height: "100%" }} alt="orange section" /></HeroImg>
                    </Visible>
                  </HeroImgWrapper>
                </Fade>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default OrangeSection;
