// Library Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// CSS Imports
import "./global.css";
import { userTrack } from "../Common";

// CSS-in-JS
const TabContainer = styled.div`
  width: 700px;
  margin: 0px auto;
  text-align: center;
  margin-top: 45px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 300px;
    margin-left: -7px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 15px;
  border-bottom: 1px solid #dedede;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active == "true" ? "#242424" : "#F4F4F4")};
  color: ${(props) => (props.active == "true" ? "#ffffff" : "#2F2F2F")};
  font-family: Roboto;
  font-size: var(--size20);
  border: none;
  font-weight: var(--fw300);
  line-height: 40px;
  width: fit-content;
  height: fit-content;
  border-radius: 12px 12px 0px 0px;
  position: relative;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 14px;
    line-height: 14px;
    height: 70px;
  }

  &:hover {
    background-color: ${(props) => (props.active == "true" ? "#242424" : "#F4F4F4")};
  }
`;

const EarlyAccessButton = styled.span`
  position: absolute;
  width: 86px;
  height: 30px;
  border-radius: 12px;
  border: 1px solid #00a81c;
  font-family: Roboto;
  font-size: var(--size11);
  font-weight: var(--fw700);
  line-height: 18px;
  text-align: center;
  color: #00a81c;
  background-color: #dcfce7;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2px;
  top: -24px;
`;

const Content = styled.div`
  /* padding: 20px;
  border-radius: 5px; */
`;

const StyledContent1 = styled.div``;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size60);
  font-weight: var(--fw400);
  line-height: 60px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 10px;
  margin-bottom: 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: var(--size35);
    line-height: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const StrongText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw700);
  line-height: var(--lh26);
  text-align: left;
  margin-bottom: 5px;
  text-align: left;
  color: var(--text-color-dark-liver);
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);

  strong {
    font-size: var(--size20);
    font-weight: var(--fw700);
    color: var(--text-color-dark-liver);
  }
`;

const Space = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ListUL = styled.ul`
  text-align: left;
  padding-left: 21px;
`;

const ListLi = styled.li`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
`;

const StyledContent2 = styled.div``;

const StyledContent3 = styled.div``;

const TabSwitcher = ({ setActiveTab, activeTab }) => {
  const tabContent = [
    {
      label: "Summarization",
      content: (
        <StyledContent1>
          <Title>Summarize. Query.</Title>
          <Title>Get Answers.</Title>
          <StrongText>Stop drowning in documents and reports.</StrongText>
          <Text>
            Spending 30% of your week reading and analyzing articles, research
            papers and market reports?
          </Text>
          <Space></Space>
          <Text>No more.</Text>
          <Space></Space>
          <Text>
            Get AI to summarize dense reports, write executive summaries, answer
            questions and more.
          </Text>
        </StyledContent1>
      ),
      earlyAccess: false,
    },
    {
      label: "Company Search",
      content: (
        <StyledContent2>
          <Title>Search Topics. Scout Companies.</Title>
          <Title>Get rich profiles</Title>
          {/* <StrongText></StrongText> */}
          <Text>
            Tired of generic search tools that lack the details you need to
            evaluate companies?
          </Text>
          <Space></Space>
          <Text>
            Get the Full Picture: Overview, Technology details, Financials,
            Latest Activities and More.{" "}
          </Text>
          <Space></Space>
          <Text>
            Use Ideapoke's company profile tool to generate rich reports for
            scouting and competitive research process.
          </Text>
        </StyledContent2>
      ),
      earlyAccess: false,
    },
    {
      label: "Video Transcription",
      content: (
        <StyledContent3>
          <Title>Share a Link. Summarize.</Title>
          <Title>Jump to Insights.</Title>

          <Text>Videos are amazing for research. But you are Overloaded!</Text>
          <Text>Summarize a 30-minute video in 2 minutes.</Text>
          <Space></Space>
          <Text>
            <strong>Get to the Point Faster:</strong> Summarize key information
            and insights from videos in seconds.
          </Text>
          <Space></Space>
          <StrongText>
            With Ideapoke's AI-powered Video Transcription Tool:
          </StrongText>
          <ListUL>
            <ListLi>Automatically extracts key points and highlights.</ListLi>
            <ListLi>
              Provides concise summaries, saving you valuable time.
            </ListLi>
            <ListLi>
              Enables you to quickly grasp the essence of any video.
            </ListLi>
          </ListUL>
        </StyledContent3>
      ),
      earlyAccess: true,
    },
  ];
  // const switchInterval = 5000; // Switch every 5 seconds

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveTab((prevActiveTab) => (prevActiveTab + 1) % tabContent.length);
  //   }, switchInterval);

  //   return () => clearInterval(interval);
  // }, [tabContent.length, switchInterval]);

  return (
    <TabContainer>
      <Tabs>
        {tabContent.map((tab, index) => (
          <Tab
            key={index}
            active={String(index === activeTab)}
            onClick={() => {
              setActiveTab(index);
              if (tab.label == "Summarization") {
                userTrack(
                  "",
                  "Onclick of AI Summarization on AI Research Tools",
                  "Platform"
                );
              } else if (tab.label == "Company Search") {
                userTrack(
                  "",
                  "Onclick of AI Company Search on AI Research Tools",
                  "Platform"
                );
              } else if (tab.label == "Video Transcription") {
                userTrack(
                  "",
                  "Onclick of AI Video Transcription AI Research Tools",
                  "Platform"
                );
              }
            }}
          >
            <strong>AI</strong> {tab.label}
            {tab.earlyAccess && (
              <EarlyAccessButton>Early Access</EarlyAccessButton>
            )}
          </Tab>
        ))}
      </Tabs>
      <Content style={{ minHeight: "360px" }}>
        {tabContent[activeTab].content}
      </Content>
    </TabContainer>
  );
};

export default TabSwitcher;
