import React, { Children, useEffect, useState } from "react";
import styled from "styled-components";
import Close from "../images/cookie-close.svg";
import Button from "../Button";
import Cookies from "js-cookie";
import Image from "next/image";

const CookieWrap = styled.div`
  background-color: #ebebeb;
  padding: 10px 50px;
  position: fixed;
  position: fixed;
  width: 94%;
  bottom: 0px;
  z-index: 10;

  /* @media screen and (min-width: 1400px) {
    width: 99vw;
  }
  @media screen and (max-width: 767px) {
    width: 76vw;
  } */
@media (min-width: 320px) and (max-width: 480px) {
            width: 100%;
        position: fixed;
        bottom: -4px;
        padding-top: 17px;
        padding-bottom: 19px;
  }
`;
const CloseImg = styled(Image)`
  /* position: absolute;
  right: 20px;
  cursor: pointer; */

      position: absolute;
    right: 25px;
    top: 13px;
    cursor: pointer;
    background:inherit;
    border-radius: 50%;
    width: 20px;
    padding: 10px;
    height: 20px;

    &:hover {
      background: #fefbfb;
    }
`;
const CookieTxt = styled.p`
  margin: 0;
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.64px;
  letter-spacing: 1px;
  width: 1080px;
  margin-top: 10px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
const CookieSubTxt = styled.p`
  margin: 0;
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.64px;
  letter-spacing: 1px;
  width: 1180px;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
`;

export default function Cookie(props) {

  const [cookiesAccepted, setCookiesAccepted] = useState(
    Cookies.get("cookiesAccepted") === "true"
  );

  const [isVisible, setIsVisible] = useState(false);

  const handleAccept = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 365 });
    setCookiesAccepted(true);
    setIsVisible(false);
  };

  const HandleCancel = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (Cookies.get("cookiesAccepted") === "true") {
      setCookiesAccepted(true);
    } else {
      setIsVisible(true)
    }
  }, []);

  return (
    <div>
      {isVisible && !cookiesAccepted && (
        <CookieWrap className="cookies slidingDiv10">
          <CloseImg src={Close} onClick={HandleCancel} />
          <CookieTxt>
            Ideapoke website generally uses certain cookies to enable better
            interactions with our sites and services. Use of these cookies,
            which may be stored on your device, permits us to improve and
            customize your experience. You can read more about your cookie
            choices at our privacy policy{" "}
            <a
              style={{
                textDecoration: "underline",
                color: "#0418CA",
                cursor: "pointer",
              }}
              href={process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/privacy.html"}
            >
              here
            </a>
          </CookieTxt>
          <CookieSubTxt>
            By continuing to use this site you are consenting to these choices.
          </CookieSubTxt>
          <BtnWrap>
            <Button
              onClick={handleAccept}
              text="Allow Cookies"
              variant="solidOrange"
              width="130px"
              height="50px"
            ></Button>
          </BtnWrap>
        </CookieWrap>
      )}
    </div>
  );
}
