//Library Imports
import React from "react";
import styled, { keyframes } from "styled-components";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import { setConfiguration } from "react-grid-system";
import { Fade } from "react-awesome-reveal";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";
import "./Research.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { PillsTag } from "./PillsTag";
import { TransparentBtn } from "./TransparentBtn";

// Import Images
import PlaceholderBlue from "./images/PlaceholderBlue.svg";
import ArrowLeft from "./images/ArrowLeft.svg";
import { BlackBtn } from "./BlackBtn";
import { userTrack } from "../Common";

// Import Videos
// import Video3 from "./video/Video3.mp4";
import VideoGif3 from "./video/VideoGif3.gif";
// import VideoGif3 from "./video/compressedgif3.gif";
// import VideoGif3 from "./video/three.webp";

import Video3 from "./video/Video3.mp4";
import Image from "next/image";
import getConfig from "next/config";
// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  /* height: 650px; */
  height: 580px;
  background: #fff;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 939px;
  }
`;

const HeroImg = styled.div`
  position: relative;
  top: 0px;
  margin-left: -165px;
  width: 650px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);
  border-radius: 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-top: -15px;

  }
`;

const Video = styled.video`
  position: relative;
  top: 0px;
  margin-left: 0px;
  width: 517px;
  border-radius: 10px;
  border: 1px solid #e5e3e0;
  box-shadow: 0 11px 40px -5px rgba(75, 32, 19, 0.19);

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
    margin-top: -44px;
  }
`;

const Box = styled.div`
  /* margin-top: 30px; */
  margin-bottom: 100px;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 65px;
  text-align: left;
  color: var(--text-color-black);
  margin-top: 5px;
  margin-bottom: 5px;
  text-wrap: nowrap;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    text-align: center;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
  text-wrap: wrap;
  margin-top: 30px;

  span {
    font-size: var(--size20);
    font-weight: var(--fw400);
    line-height: var(--lh20);
    text-align: left;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    text-wrap: wrap;
    text-align: center;
  }
`;

const BottomText = styled.p`
  font-family: Roboto;
  font-size: var(--size20);
  font-weight: var(--fw300);
  line-height: var(--lh25);
  text-align: left;
  color: var(--text-color-dark-liver);
`;

const DoubleBtnWrapper = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

const moveArrow = keyframes`
  0%, 100% {
    right: -74px;
  }
  50% {
    right: -84px;
  }
`;

const Img = styled.div`
  position: absolute;
  right: -74px;
  top: -64px;
  animation: ${moveArrow} 1s ease-in-out infinite;
`;

const HeroImgWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0 auto;
    width: 300px;
  }
`;

const BlueSection = React.memo(() => {

  const { publicRuntimeConfig } = getConfig();

  const HandleContact = async () => {
    await userTrack("", "Onclick of contact sales", "Platform");
    window.location.href = process.env.NODE_ENV == "development" ? "/Contact-Sales" : publicRuntimeConfig.assetPrefix + "/Contact-Sales.html";
  };
  const HandleLearnMore = async () => {
    await userTrack("", "Onclick of Learn More Research Elite", "Platform");
    window.location.href = process.env.NODE_ENV == "development" ? "/Research-Elite" : publicRuntimeConfig.assetPrefix + "/Research-Elite.html";
  };


  const videoBasePath = publicRuntimeConfig.assetPrefix || '';

  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container>
            <Row>
              <Col
                xl={6}
                lg={6}
                sm={6}
                xs={12}
                order={{ xs: 2, xl: 1, lg: 1, sm: 1 }}
              >
                <Fade duration={1500} fraction={0.5} triggerOnce={true}>
                  <HeroImgWrapper>
                    {/* <HeroImg src={PlaceholderBlue}></HeroImg> */}
                    <Hidden xs>
                      <Video
                        width="100%"
                        height="auto"
                        //controls
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="metadata"
                      >
                        <source alt={"Research Elite:Powerful AI Data. Curated Insights for Planning"} src={videoBasePath + Video3} type="video/mp4" />
                      </Video>
                    </Hidden>

                    <Visible xs>
                      <HeroImg ><Image src={VideoGif3} alt="gif3" style={{ width: "100%", height: "100%", top: "200px" }} /></HeroImg>
                    </Visible>
                  </HeroImgWrapper>
                </Fade>
              </Col>

              <Col
                xl={6}
                lg={6}
                sm={6}
                xs={12}
                order={{ xs: 1, xl: 1, lg: 1, sm: 1 }}
              >
                <Box>
                  <PillsTag
                    as="div"
                    width="220px"
                    height="49px"
                    backgroundcolor="#E0EDFF"
                    color="#4D5AC8"
                    className="pill-center"
                  >
                    Research <span>Elite</span>
                  </PillsTag>
                  <Title>Powerful AI Data.</Title>
                  <Title>
                    Curated Insights for <br />
                    Planning.
                  </Title>
                  <Text>
                    Fine-tune your research through iterations using AI Agents &
                    workflows.
                  </Text>
                  <BottomText>
                    Replace expensive Consultants with In-Depth analysis and
                    Customized Outputs for your Planning and Strategy.
                  </BottomText>

                  <DoubleBtnWrapper>
                    <BlackBtn
                      onClick={HandleContact}
                      width="217px"
                      height="56px"
                    >
                      Contact Sales
                    </BlackBtn>

                    <TransparentBtn onClick={HandleLearnMore}>
                      Learn More
                    </TransparentBtn>
                    <ArrowWrapper>
                      <Img><Image alt="ArrowLeft" src={ArrowLeft} /></Img>
                    </ArrowWrapper>
                  </DoubleBtnWrapper>
                </Box>
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default BlueSection;
