//Library Imports
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { Fade, Slide } from "react-awesome-reveal";

// CSS Imports
import "./global.css";

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import { BlackBtn } from "./BlackBtn";

// Import Images
import HumanoidImage from "./images/HumanoidImage.svg";
import { userTrack } from "../Common";
import Image from "next/image";
import getConfig from "next/config";

// CSS-in-JS

const CenterBox = styled.div`
  /* max-width: 1209px; */
  margin: 0 auto;
`;

const MainWrapper = styled.section`
  height: 1118px;
  background: var(--bg-color-old-lace);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 830px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  color: var(--text-color-black);
  width: 848px;
  margin: 0 auto;
  margin-bottom: 15px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    width: 269px;
  }
`;

const OrangeTitle = styled.h1`
  font-family: Roboto;
  margin: 0 auto;
  font-size: var(--size64);
  font-weight: var(--fw400);
  line-height: 71px;
  text-align: center;
  color: var(--text-color-halloween-orange);
  margin-bottom: 40px;

  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 51px;
    width: 240px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 25px;
  font-weight: 300;
  line-height: 33px;
  text-align: center;
  color: #515151;
  width: 581px;
  margin: 0 auto;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 240px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    width: 280px;
    margin: 0 auto;
  }
`;

const HumanoidSection = React.memo(() => {
  const { publicRuntimeConfig } = getConfig();



  const HandleExplorePlatform = async () => {
    await userTrack(
      "",
      "Onclick of Explore Ideapoke Research Platform",
      "Platform"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/AI-Business-Research-Platform" : publicRuntimeConfig.assetPrefix + "/AI-Business-Research-Platform.html";
  };
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <CenterBox>
            <Container>
              <Row>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <Slide
                    duration={1500}
                    fraction={0.5}
                    triggerOnce={true}
                    direction="down"
                  >
                    <Title>Responsible Research</Title>
                    <OrangeTitle>AI Powered, Human verified</OrangeTitle>
                  </Slide>
                  <Slide
                    duration={1800}
                    fraction={0.5}
                    triggerOnce={true}
                    direction="down"
                  >
                    <Text>
                      Make confident decisions for strategy and planning. Secure
                      research, reliable results.
                    </Text>
                  </Slide>
                  <Fade duration={2000} fraction={0.5} triggerOnce={true}>
                    <ImageWrapper>
                      <Image className="Humanoid-section-mobile" alt={"Responsible Research AI Powered, Human verified"} src={HumanoidImage}></Image>
                    </ImageWrapper>
                  </Fade>
                  <Fade duration={2000} fraction={0.5} triggerOnce={true}>
                    <ButtonWrapper>
                      <BlackBtn
                        onClick={HandleExplorePlatform}
                        width="358px"
                        height="56px"
                      >
                        Explore Ideapoke Research Platform
                      </BlackBtn>
                    </ButtonWrapper>
                  </Fade>
                </Col>
              </Row>
            </Container>
          </CenterBox>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default HumanoidSection;
