//Library Imports
import React from "react";
import styled from "styled-components";

// Import Images
import BlockIcon from "./images/BlockIcon.svg";
import Image from "next/image";

// CSS-in-JS
const Img = styled.img`
  position: relative;
`;

const IconWrapper = styled.div`
  background-color: ${(props) => props.bgcolor || "#f6c143"};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconPlaceholder = ({ src, bgColor }) => {
  return (
    <>
      <IconWrapper bgcolor={bgColor}>
        <Image alt={bgColor == "#f6c143" ? "Any Research. One Platform" : bgColor == "#baea86" ? "AI meant for Research" : bgColor == "#a2befb" ? "Collaborative Research" : ""} src={src}></Image>
      </IconWrapper >
    </>
  );
};

export default IconPlaceholder;
