//Library Imports
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { setConfiguration } from "react-grid-system";

setConfiguration({
  containerWidths: [540, 740, 960, 1240, 1540, 1810], //Container width
});

// CSS Imports
import "./global.css";

// CSS-in-JS
const MainWrapper = styled.section`
  max-width: 1209px;
  margin: 0 auto;
  height: 587px;
  background: #fff;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 1130px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: var(--size45);
  font-weight: var(--fw400);
  line-height: 42px;
  text-align: center;
  color: var(--text-color-black);
  width: 1013px;
  margin: 0 auto;
  margin-bottom: 70px;
  margin-top:100px;
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 35px;
    line-height: 55px;
    width: 330px; 
    margin-top:80px;

  }
`;

// Component Imports
import { RobotoFontWrapper } from "./RobotoFont";
import ClientCarousel from "./ClientCarousel";

const ClientComponent = React.memo(({ title }) => {
  return (
    <>
      <RobotoFontWrapper>
        <MainWrapper>
          <Container fluid>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <Title>{title}</Title>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Col xl={12} lg={12} sm={12} xs={12}>
                <ClientCarousel />
              </Col>
            </Row>
          </Container>
        </MainWrapper>
      </RobotoFontWrapper>
    </>
  );
});

export default ClientComponent;
